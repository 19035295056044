export default function TitleRow(): JSX.Element {
    return (
        <tr>
            <th>
                Resource
            </th>
            <th>
                Income
            </th>
            <th>
                Tax
            </th>
        </tr>
    )
}