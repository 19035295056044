interface ChevronLeftProps {
    color: string
}

export default function ChrevronLeft({color}: ChevronLeftProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.125 21.6129L6.35 12.8379C6.23333 12.7212 6.14583 12.592 6.0875 12.4504C6.02917 12.3087 6 12.1629 6 12.0129C6 11.8629 6.02917 11.717 6.0875 11.5754C6.14583 11.4337 6.23333 11.3045 6.35 11.1879L15.125 2.41288C15.3917 2.14621 15.725 2.00871 16.125 2.00038C16.525 1.99205 16.8583 2.12121 17.125 2.38788C17.4083 2.67121 17.55 3.01288 17.55 3.41288C17.55 3.81288 17.4083 4.15455 17.125 4.43788L9.55 12.0129L17.125 19.5879C17.4083 19.8712 17.55 20.2087 17.55 20.6004C17.55 20.992 17.4167 21.3212 17.15 21.5879C16.8667 21.8712 16.525 22.0129 16.125 22.0129C15.725 22.0129 15.3917 21.8795 15.125 21.6129Z" fill={color}/>
    </svg>

  )
}
